/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"

import { createBrowserRouter, RouterProvider } from "react-router-dom"

export const HomePage = lazy(async () => await import("./pages/Home"))
export const ErrorPage = lazy(async () => await import("./pages/Error"))
export const AboutPage = lazy(async () => await import("./pages/About"))
export const ContactPage = lazy(async () => await import("./pages/Contact"))
export const CareersPage = lazy(async () => await import("./pages/Careers"))
export const CaseStudiesPage = lazy(async () => await import("./pages/CaseStudies"))
export const MenuPage = lazy(async () => await import("./pages/Menu"))
export const JobDetailsPage = lazy(async () => await import("./pages/JobDetails"))
export const PrivacyPolicyPage = lazy(async () => await import("./pages/Policy"))
export const NewsDetails = lazy(async () => await import("./pages/NewsDetail"))
export const ListPage = lazy(async () => await import("./pages/ListPage"))

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomePage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/About",
		element: <AboutPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/Contact",
		element: <ContactPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/Careers",
		element: <CareersPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/CaseStudies",
		element: <CaseStudiesPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/Menu",
		element: <MenuPage />,
		errorElement: <ErrorPage />,
	},
	{ path: "/JobDetails", element: <JobDetailsPage />, errorElement: <ErrorPage /> },
	{ path: "/Policy", element: <PrivacyPolicyPage />, errorElement: <ErrorPage /> },
	{
		path: "news/:id",
		element: <NewsDetails />,
		errorElement: <ErrorPage />
	},
	{
		path: "/list/:contentType",
		element: <ListPage/>,
		errorElement: <ErrorPage />
	}
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
